import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql } from "gatsby";
import Footer from "../components/Footer/Footer";
import Topbar from "../components/Topbar/Topbar";
import config from "../../data/SiteConfig";
import Layout from "../components/layout";

const Wrapper = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.75;
  margin: auto; // IE
  margin: calc(var(--gutter) * 2) auto calc(var(--gutter) * 4);
  max-width: 1000px; // IE
  max-width: var(--max-width);
  padding: var(--gutter);
`;

export default class PrivacyPolicy extends React.Component {
  render() {
    const categories = this.props.data.categories.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title="Privacy policy">
            <meta name="theme-color" content={config.themes.default.main} />
          </Helmet>
          <Topbar
            categories={categories}
            sharePath="/privacy-policy/"
            shareTitle="Privacy Policy"
          />
          <Wrapper>
            <h1>Privacy Policy</h1>
            <p>
              Questa Applicazione raccoglie alcuni Dati Personali dei propri
              Utenti.
            </p>
            <h2>Titolare del Trattamento dei Dati</h2>
            <p>Vivigratis SA - Via Livio, 12 - 6830 Chiasso (Svizzera)</p>
            <p>
              <strong>Indirizzo email del Titolare:</strong> info@vivigratis.com
            </p>
            <h2>Tipologie di Dati raccolti</h2>
            <p>
              Fra i Dati Personali raccolti da questa Applicazione, in modo
              autonomo o tramite terze parti, ci sono: Cookie e Dati di
              utilizzo.
            </p>
            <p>
              Dettagli completi su ciascuna tipologia di dati raccolti sono
              forniti nelle sezioni dedicate di questa privacy policy o mediante
              specifici testi informativi visualizzati prima della raccolta dei
              dati stessi.
              <br />
              I Dati Personali possono essere liberamente forniti
              dall&rsquo;Utente o, nel caso di Dati di Utilizzo, raccolti
              automaticamente durante l&rsquo;uso di questa Applicazione.
              <br />
              Se non diversamente specificato, tutti i Dati richiesti da questa
              Applicazione sono obbligatori. Se l&rsquo;Utente rifiuta di
              comunicarli, potrebbe essere impossibile per questa Applicazione
              fornire il Servizio. Nei casi in cui questa Applicazione indichi
              alcuni Dati come facoltatitivi, gli Utenti sono liberi di
              astenersi dal comunicare tali Dati, senza che ciò abbia alcuna
              conseguenza sulla disponibilità del Servizio o sulla sua
              operatività.
              <br />
              Gli Utenti che dovessero avere dubbi su quali Dati siano
              obbligatori, sono incoraggiati a contattare il Titolare.
              <br />
              L&rsquo;eventuale utilizzo di Cookie - o di altri strumenti di
              tracciamento - da parte di questa Applicazione o dei titolari dei
              servizi terzi utilizzati da questa Applicazione, ove non
              diversamente precisato, ha la finalità di fornire il Servizio
              richiesto dall&rsquo;Utente, oltre alle ulteriori finalità
              descritte nel presente documento e nella Cookie Policy, se
              disponibile.
            </p>
            <p>
              L&rsquo;Utente si assume la responsabilità dei Dati Personali di
              terzi ottenuti, pubblicati o condivisi mediante questa
              Applicazione e garantisce di avere il diritto di comunicarli o
              diffonderli, liberando il Titolare da qualsiasi responsabilità
              verso terzi.
            </p>
            <h2>Modalit&agrave; e luogo del trattamento dei Dati raccolti</h2>
            <h3>Modalit&agrave; di trattamento</h3>
            <p>
              Il Titolare adotta le opportune misure di sicurezza volte ad
              impedire l&rsquo;accesso, la divulgazione, la modifica o la
              distruzione non autorizzate dei Dati Personali.
              <br />
              Il trattamento viene effettuato mediante strumenti informatici e/o
              telematici, con modalità organizzative e con logiche strettamente
              correlate alle finalità indicate. Oltre al Titolare, in alcuni
              casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
              nell&rsquo;organizzazione di questa Applicazione (personale
              amministrativo, commerciale, marketing, legali, amministratori di
              sistema) ovvero soggetti esterni (come fornitori di servizi
              tecnici terzi, corrieri postali, hosting provider, società
              informatiche, agenzie di comunicazione) nominati anche, se
              necessario, Responsabili del Trattamento da parte del Titolare.
              L&rsquo;elenco aggiornato dei Responsabili potrà sempre essere
              richiesto al Titolare del Trattamento.
            </p>
            <h3>Base giuridica del trattamento</h3>
            <p>
              Il Titolare tratta Dati Personali relativi all&rsquo;Utente in
              caso sussista una delle seguenti condizioni:
            </p>
            <ul>
              <li>
                l&rsquo;Utente ha prestato il consenso per una o più finalità
                specifiche; Nota: in alcuni ordinamenti il Titolare può essere
                autorizzato a trattare Dati Personali senza che debba sussistere
                il consenso dell&rsquo;Utente o un&rsquo;altra delle basi
                giuridiche specificate di seguito, fino a quando l&rsquo;Utente
                non si opponga (“opt-out”) a tale trattamento. Ciò non è
                tuttavia applicabile qualora il trattamento di Dati Personali
                sia regolato dalla legislazione europea in materia di protezione
                dei Dati Personali;
              </li>
              <li>
                il trattamento è necessario all&rsquo;esecuzione di un contratto
                con l&rsquo;Utente e/o all&rsquo;esecuzione di misure
                precontrattuali;
              </li>
              <li>
                il trattamento è necessario per adempiere un obbligo legale al
                quale è soggetto il Titolare;
              </li>
              <li>
                il trattamento è necessario per l&rsquo;esecuzione di un compito
                di interesse pubblico o per l&rsquo;esercizio di pubblici poteri
                di cui è investito il Titolare;
              </li>
              <li>
                il trattamento è necessario per il perseguimento del legittimo
                interesse del Titolare o di terzi.
              </li>
            </ul>
            <p>
              E&rsquo; comunque sempre possibile richiedere al Titolare di
              chiarire la concreta base giuridica di ciascun trattamento ed in
              particolare di specificare se il trattamento sia basato sulla
              legge, previsto da un contratto o necessario per concludere un
              contratto.
            </p>
            <h3>Luogo</h3>
            <p>
              I Dati sono trattati presso le sedi operative del Titolare ed in
              ogni altro luogo in cui le parti coinvolte nel trattamento siano
              localizzate. Per ulteriori informazioni, contatta il Titolare.
              <br />
              I Dati Personali dell&rsquo;Utente potrebbero essere trasferiti in
              un paese diverso da quello in cui l&rsquo;Utente si trova. Per
              ottenere ulteriori informazioni sul luogo del trattamento
              l&rsquo;Utente può fare riferimento alla sezione realtiva ai
              dettagli sul trattamento dei Dati Personali.
            </p>
            <p>
              L&rsquo;Utente ha diritto a ottenere informazioni in merito alla
              base giuridica del trasferimento di Dati al di fuori
              dell&rsquo;Unione Europea o ad un&rsquo;organizzazione
              internazionale di diritto internazionale pubblico o costituita da
              due o più paesi, come ad esempio l&rsquo;ONU, nonché in merito
              alle misure di sicurezza adottate dal Titolare per proteggere i
              Dati.
              <br />
              <br />
              Qualora abbia luogo uno dei trasferimenti appena descritti,
              l&rsquo;Utente può fare riferimento alle rispettive sezioni di
              questo documento o chiedere informazioni al Titolare contattandolo
              agli estremi riportati in apertura.
            </p>
            <h3>Periodo di conservazione</h3>
            <p>
              I Dati sono trattati e conservati per il tempo richiesto dalle
              finalità per le quali sono stati raccolti.
            </p>
            <p>Pertanto:</p>
            <ul>
              <li>
                I Dati Personali raccolti per scopi collegati
                all&rsquo;esecuzione di un contratto tra il Titolare e
                l&rsquo;Utente saranno trattenuti sino a quando sia completata
                l&rsquo;esecuzione di tale contratto.
              </li>
              <li>
                I Dati Personali raccolti per finalità riconducibili
                all&rsquo;interesse legittimo del Titolare saranno trattenuti
                sino al soddisfacimento di tale interesse. L&rsquo;Utente può
                ottenere ulteriori informazioni in merito all&rsquo;interesse
                legittimo perseguito dal Titolare nelle relative sezioni di
                questo documento o contattando il Titolare.
              </li>
            </ul>
            <p>
              Quando il trattamento è basato sul consenso dell&rsquo;Utente, il
              Titolare può conservare i Dati Personali più a lungo sino a quando
              detto consenso non venga revocato. Inoltre il Titolare potrebbe
              essere obbligato a conservare i Dati Personali per un periodo più
              lungo in ottemperanza ad un obbligo di legge o per ordine di
              un&rsquo;autorità.
              <br />
              <br />
              Al termine del periodo di conservazioni i Dati Personali saranno
              cancellati. Pertanto, allo spirare di tale termine il diritto di
              accesso, cancellazione, rettificazione ed il diritto alla
              portabilità dei Dati non potranno più essere esercitati.
            </p>
            <h2>Finalit&agrave; del Trattamento dei Dati raccolti</h2>
            <p>
              I Dati dell&rsquo;Utente sono raccolti per consentire al Titolare
              di fornire i propri Servizi, così come per le seguenti finalità:
              Statistica.
            </p>
            <p>
              Per ottenere ulteriori informazioni dettagliate sulle finalità del
              trattamento e sui Dati Personali concretamente rilevanti per
              ciascuna finalità, l&rsquo;Utente può fare riferimento alle
              relative sezioni di questo documento.
            </p>
            <h2>Dettagli sul trattamento dei Dati Personali</h2>
            <p>
              I Dati Personali sono raccolti per le seguenti finalità ed
              utilizzando i seguenti servizi:
            </p>
            <ul>
              <li>
                <h3>Statistica</h3>
                <p>
                  I servizi contenuti nella presente sezione permettono al
                  Titolare del Trattamento di monitorare e analizzare i dati di
                  traffico e servono a tener traccia del comportamento
                  dell&rsquo;Utente.
                </p>
                <h4>Google Analytics (Google Inc.)</h4>
                <p>
                  Google Analytics &egrave; un servizio di analisi web fornito
                  da Google Inc. (&ldquo;Google&rdquo;). Google utilizza i Dati
                  Personali raccolti allo scopo di tracciare ed esaminare
                  l&rsquo;utilizzo di questa Applicazione, compilare report e
                  condividerli con gli altri servizi sviluppati da Google.
                  <br />
                  Google potrebbe utilizzare i Dati Personali per
                  contestualizzare e personalizzare gli annunci del proprio
                  network pubblicitario.
                </p>
                <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                <p>
                  Luogo del trattamento: USA &ndash;&nbsp;
                  <a
                    href="https://www.google.com/intl/it/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opt Out
                  </a>
                </p>
              </li>
            </ul>
            <h2>Diritti dell&rsquo;Utente</h2>
            <p>
              Gli Utenti possono esercitare determinati diritti con riferimento
              ai Dati trattati dal Titolare.
            </p>
            <p>In particolare, l&rsquo;Utente ha il diritto di:</p>
            <ul>
              <li>
                <b>revocare il consenso in ogni momento.</b> L&rsquo;Utente può
                revocare il consenso al trattamento dei propri Dati Personali
                precedentemente espresso.{" "}
              </li>
              <li>
                <b>opporsi al trattamento dei propri Dati.</b> L&rsquo;Utente
                può opporsi al trattamento dei propri Dati quando esso avviene
                su una base giuridica diversa dal consenso. Ulteriori dettagli
                sul diritto di opposizione sono indicati nella sezione
                sottostante.
              </li>
              <li>
                <b>accedere ai propri Dati.</b> L&rsquo;Utente ha diritto ad
                ottenere informazioni sui Dati trattati dal Titolare, su
                detereminati aspetti del trattamento ed a ricevere una copia dei
                Dati trattati.
              </li>
              <li>
                <b>verificare e chiedere la rettificazione.</b> L&rsquo;Utente
                può verificare la correttezza dei propri Dati e richiederne
                l&rsquo;aggiornamento o la correzione.
              </li>
              <li>
                <b>ottenere la limitazione del trattamento.</b> Quando ricorrono
                determinate condizioni, l&rsquo;Utente può richiedere la
                limitazione del trattamento dei propri Dati. In tal caso il
                Titolare non tratterà i Dati per alcun altro scopo se non la
                loro conservazione.
              </li>
              <li>
                <b>
                  ottenere la cancellazione o rimozione dei propri Dati
                  Personali.
                </b>{" "}
                Quando ricorrono determinate condizioni, l&rsquo;Utente può
                richiedere la cancellazione dei propri Dati da parte del
                Titolare.
              </li>
              <li>
                <b>
                  ricevere i propri Dati o farli trasferire ad altro titolare.
                </b>{" "}
                L&rsquo;Utente ha diritto di ricevere i propri Dati in formato
                strutturato, di uso comune e leggibile da dispositivo automatico
                e, ove tecnicamente fattibile, di ottenerne il trasferimento
                senza ostacoli ad un altro titolare. Questa disposizione è
                applicabile quando i Dati sono trattati con strumenti
                automatizzati ed il trattamento è basato sul consenso
                dell&rsquo;Utente, su un contratto di cui l&rsquo;Utente è parte
                o su misure contrattuali ad esso connesse.
              </li>
              <li>
                <b>proporre reclamo.</b> L&rsquo;Utente può proporre un reclamo
                all&rsquo;autorità di controllo della protezione dei dati
                personali competente o agire in sede giudiziale.
              </li>
            </ul>
            <h3>Dettagli sul diritto di opposizione</h3>
            <p>
              Quando i Dati Personali sono trattati nell&rsquo;interesse
              pubblico, nell&rsquo;esercizio di pubblici poteri di cui è
              investito il Titolare oppure per perseguire un interesse legittimo
              del Titolare, gli Utenti hanno diritto ad opporsi al trattamento
              per motivi connessi alla loro situazione particolare.
            </p>
            <p>
              Si fa presente agli Utenti che, ove i loro Dati fossero trattati
              con finalità di marketing diretto, possono opporsi al trattamento
              senza fornire alcuna motivazione. Per scoprire se il Titolare
              tratti dati con finalità di marketing diretto gli Utenti possono
              fare riferimento alle rispettive sezioni di questo documento.
            </p>
            <h3>Come esercitare i diritti</h3>
            <p>
              Per esercitare i diritti dell&rsquo;Utente, gli Utenti possono
              indirizzare una richiesta agli estremi di contatto del Titolare
              indicati in questo documento. Le richieste sono depositate a
              titolo gratuito e evase dal Titolare nel più breve tempo
              possibile, in ogni caso entro un mese.
            </p>
            <h2>Cookie Policy</h2>
            <p>
              Questa Applicazione fa utilizzo di Cookie. Per saperne di più e
              per un&rsquo;informativa dettagliata, puoi consultare la{" "}
              <a href="/cookie-policy/">Cookie Policy</a>
              .
            </p>
            <h2>Ulteriori informazioni sul trattamento</h2>
            <h3>Difesa in giudizio</h3>
            <p>
              I Dati Personali dell&rsquo;Utente possono essere utilizzati da
              parte del Titolare in giudizio o nelle fasi preparatorie alla sua
              eventuale instaurazione per la difesa da abusi nell&rsquo;utilizzo
              di questa Applicazione o dei Servizi connessi da parte
              dell&rsquo;Utente.
              <br />
              L&rsquo;Utente dichiara di essere consapevole che il Titolare
              potrebbe essere obbligato a rivelare i Dati per ordine delle
              autorità pubbliche.
            </p>
            <h3>Informative specifiche</h3>
            <p>
              Su richiesta dell&rsquo;Utente, in aggiunta alle informazioni
              contenute in questa privacy policy, questa Applicazione potrebbe
              fornire all&rsquo;Utente delle informative aggiuntive e
              contestuali riguardanti Servizi specifici, o la raccolta ed il
              trattamento di Dati Personali.
            </p>
            <h3>Log di sistema e manutenzione</h3>
            <p>
              Per necessità legate al funzionamento ed alla manutenzione, questa
              Applicazione e gli eventuali servizi terzi da essa utilizzati
              potrebbero raccogliere Log di sistema, ossia file che registrano
              le interazioni e che possono contenere anche Dati Personali, quali
              l&rsquo;indirizzo IP Utente.
            </p>
            <h3>Informazioni non contenute in questa policy</h3>
            <p>
              Ulteriori informazioni in relazione al trattamento dei Dati
              Personali potranno essere richieste in qualsiasi momento al
              Titolare del Trattamento utilizzando gli estremi di contatto.
            </p>
            <h3>Risposta alle richieste „Do Not Track”</h3>
            <p>
              Questa Applicazione non supporta le richieste “Do Not Track”.
              <br />
              Per scoprire se gli eventuali servizi di terze parti utilizzati le
              supportino, l&rsquo;Utente è invitato a consultare le rispettive
              privacy policy.
            </p>
            <h3>Modifiche a questa privacy policy</h3>
            <p>
              Il Titolare del Trattamento si riserva il diritto di apportare
              modifiche alla presente privacy policy in qualunque momento
              dandone informazione agli Utenti su questa pagina e, se possibile,
              su questa Applicazione nonché, qualora tecnicamente e legalmente
              fattibile, inviando una notifica agli Utenti attraverso uno degli
              estremi di contatto di cui è in possesso il Titolare . Si prega
              dunque di consultare regolarmente questa pagina, facendo
              riferimento alla data di ultima modifica indicata in fondo.
              <br />
              <br />
              Qualora le modifiche interessino trattamenti la cui base giuridica
              è il consenso, il Titolare provvederà a raccogliere nuovamente il
              consenso dell&rsquo;Utente, se necessario.
            </p>
            <h3>Definizioni e riferimenti legali</h3>
            <h4>Dati Personali (o Dati)</h4>
            <p>
              Costituisce dato personale qualunque informazione che,
              direttamente o indirettamente, anche in collegamento con qualsiasi
              altra informazione, ivi compreso un numero di identificazione
              personale, renda identificata o identificabile una persona fisica.
            </p>
            <h4>Dati di Utilizzo</h4>
            <p>
              Sono le informazioni raccolte automaticamente attraverso questa
              Applicazione (anche da applicazioni di parti terze integrate in
              questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio
              dei computer utilizzati dall&rsquo;Utente che si connette con
              questa Applicazione, gli indirizzi in notazione URI (Uniform
              Resource Identifier), l&rsquo;orario della richiesta, il metodo
              utilizzato nell&rsquo;inoltrare la richiesta al server, la
              dimensione del file ottenuto in risposta, il codice numerico
              indicante lo stato della risposta dal server (buon fine, errore,
              ecc.) il paese di provenienza, le caratteristiche del browser e
              del sistema operativo utilizzati dal visitatore, le varie
              connotazioni temporali della visita (ad esempio il tempo di
              permanenza su ciascuna pagina) e i dettagli relativi
              all&rsquo;itinerario seguito all&rsquo;interno
              dell&rsquo;Applicazione, con particolare riferimento alla sequenza
              delle pagine consultate, ai parametri relativi al sistema
              operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.
            </p>
            <h4>Utente</h4>
            <p>
              L&rsquo;individuo che utilizza questa Applicazione che, salvo ove
              diversamente specificato, coincide con l&rsquo;Interessato.
            </p>
            <h4>Interessato</h4>
            <p>La persona fisica cui si riferiscono i Dati Personali.</p>
            <h4>Responsabile del Trattamento (o Responsabile)</h4>
            <p>
              La persona fisica, giuridica, la pubblica amministrazione e
              qualsiasi altro ente che tratta dati personali per conto del
              Titolare, secondo quanto esposto nella presente privacy policy.
            </p>
            <h4>Titolare del Trattamento (o Titolare)</h4>
            <p>
              La persona fisica o giuridica, l&rsquo;autorità pubblica, il
              servizio o altro organismo che, singolarmente o insieme ad altri,
              determina le finalità e i mezzi del trattamento di dati personali
              e gli strumenti adottati, ivi comprese le misure di sicurezza
              relative al funzionamento ed alla fruizione di questa
              Applicazione. Il Titolare del Trattamento, salvo quanto
              diversamente specificato, è il titolare di questa Applicazione.
            </p>
            <h4>Questa Applicazione</h4>
            <p>
              Lo strumento hardware o software mediante il quale sono raccolti e
              trattati i Dati Personali degli Utenti.
            </p>
            <h4>Servizio</h4>
            <p>
              Il Servizio fornito da questa Applicazione così come definito nei
              relativi termini (se presenti) su questo sito/applicazione.
            </p>
            <h4>Unione Europea (o UE)</h4>
            <p>
              Salvo ove diversamente specificato, ogni riferimento
              all&rsquo;Unione Europea contenuto in questo documento si intende
              esteso a tutti gli attuali stati membri dell&rsquo;Unione Europea
              e dello Spazio Economico Europeo.
            </p>
            <h4>Cookie</h4>
            <p>
              Piccola porzione di dati conservata all&rsquo;interno del
              dispositivo dell&rsquo;Utente.
            </p>
            <hr />
            <h4>Riferimenti legali</h4>
            <p>
              La presente informativa privacy è redatta sulla base di molteplici
              ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento
              (UE) 2016/679.
            </p>
            <p>
              Ove non diversamente specificato, questa informativa privacy
              riguarda esclusivamente questa Applicazione.
            </p>
            <p>Ultima modifica: 11 Maggio 2018</p>
          </Wrapper>
          <Footer />
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PrivacyPolicyQuery {
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
